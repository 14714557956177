import React from 'react';

import { Href } from '../components/href';
import { Layout } from '../components/layout';
import { MenuLink } from '../components/menu';
import { Title, Paragraph } from '../components/copy';

const Budgetbeheer = ({ location }) => (
  <Layout location={location}>
    <div className="mb-4">
      <MenuLink to="/">{'< Terug naar homepagina'}</MenuLink>
    </div>

    <Title>Budgetbeheer</Title>

    <Paragraph>
      Budgetbeheer is een vorm van beheer van financiën die deels overeenkomt
      met <Href to="/bewindvoering">bewindvoering</Href>, maar op enkele punten
      afwijkt. Wanneer iemand onder budgetbeheer komt, heeft men doorgaans geen
      of nauwelijks schulden. Het inkomen wordt door de budgetbeheerder
      ontvangen en deze zorgt ervoor dat de betalingen van de vaste lasten
      worden gedaan en het leefgeld wordt verstrekt. Een budgetplan vormt de
      basis, waarna verdere afspraken in overleg met elkaar worden afgestemd. In
      tegenstelling tot bewindvoering komt de rechtbank er bij budgetbeheer niet
      aan te pas en is er wat meer ruimte om e.e.a. naar eigen behoefte in te
      vullen.
    </Paragraph>

    <Paragraph>
      Voor aanmelding kunt u contact opnemen met één van ons.
    </Paragraph>
  </Layout>
);

export default Budgetbeheer;
